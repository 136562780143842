body {
  margin: 0;
  padding: 0;
  height: 100vh;
  color: white;
  position: relative;
  overflow: hidden;
  background-color: black; /* Domyślny czarny kolor tła */
}

body.auth-step::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/main_gif.gif');
  background-size: 50%; /* Sprawia, że obraz tła zajmuje cały ekran */
  background-repeat: no-repeat; /* Unikaj powtarzania obrazu */
  background-position: right; /* Wyśrodkuj obraz tła */
  z-index: -1;
}

body.change-background {
  background-image: url('/public/go_to_gif.gif'); /* Tymczasowy nowy obraz tła */
  background-size: cover;
  background-position: center;
}

body.change-background .login-container {
  display: none; /* Ukrycie kontenera logowania */
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.login-container h1 {
  margin-bottom: 20px;
}

.login-container input {
  width: 400px; /* Ustawienie długości inputu */
  height: 40px; /* Ustawienie wysokości inputu */
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 20px; /* Dodanie wielkości czcionki */
}

.login-container button {
  width: 200px;
  height: 40px;
  padding: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px; /* Dodanie wielkości czcionki */
}

.login-container button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}
