.code-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-form label {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.code-form input[type="text"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.code-form button {
  padding: 10px 20px;
  background-color: #727df1;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.code-form button:hover {
  background-color: #6973e3;
}

.error-message {
  color: red;
  margin-top: 10px;
}
