
.chat-container {
  width: 80vw;
  min-width: 500px;
  max-width: 900px;
  height: 90vh;
  /* background-color: #fff; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.chat-header {
  /* background-color: #717cf5; */
  color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
}

.logout-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
}

.logout-button:hover {
  color: #e9e9e9;
}

.chat-messages {
  background-color: black;
  flex-grow: 1;
  overflow-y: auto;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 80%;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
}

.user-message {
  align-self: flex-end;
  background-color: rgb(29, 29, 29);
  /* border-bottom: 2px dashed white; */
  color: white;
}

.ai-message {
  align-self: flex-start;
  background-color: black;
  color: white;
}

.chat-input form {
  display: flex;
}

.chat-input input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.chat-input button {
  padding: 10px 20px;
  /* background-color: #727df1; */
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.chat-input button:hover {
  /* background-color: #6973e3; */
}

.file-upload-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.file-upload-button {
  padding: 10px 20px;
  background-color: rgb(29, 29, 29);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.file-upload-button:hover {
  background-color: rgb(45, 45, 45);
}

.file-upload-input {
  display: none;
}

.chat-buttons {
  display: flex;
  justify-content: space-between; /* Sprawia, że elementy są rozmieszczone między sobą */
}

.loading img {
  width: 20%;
  display: block;
  margin: 0 auto;
}
